import { RoleKey } from "../../../frio-lib/src/lib/auth-module/models/role-key";
import { common } from "./environment.common";

// const APP_URL =                     "http://127.0.0.1:8000";
const APP_URL =                     "https://dev.api.frioarte-monitoring.pl";

export const environment = Object.assign(
  {},
  {
    appName:                        common.appName,
    environmentName:                "development",
    production:                     false,
    version:                        common.version,
    autoLogin:                      true,
    appURL:                         `${APP_URL}`,
    apiURL:                         `${APP_URL}/api`,
    users:                           [
      {
        "id": 5,
        "name": "Jan Operator",
        "firstname": "Jan",
        "lastname": "Operator",
        "email": "jan.operator@frioarte.pl",
        "roles": [RoleKey.operator],
        "last_login_at": "",
        "blocked": false,
        "password": "Test1234"
    },
    {
        "id": 6,
        "name": "admin",
        "firstname": "Testowy",
        "lastname": "Admin",
        "email": "admin@dev.api.frioarte-monitoring.pl",
        "roles": [RoleKey.operator, RoleKey.admin],
        "last_login_at": "",
        "blocked": false,
        "password": "Test1234"
    },
    ]
  },
  common
);
