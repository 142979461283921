<div [formGroup]="form">
  <!-- <label [attr.for]="filterField.key">{{ filterField.label }}</label> -->
  <div class="w-full">
    @switch (filterField.controlType) {
      @case ('textbox') {
        <!-- <input [formControlName]="filterField.key" [id]="filterField.key" [type]="filterField.type"> -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{filterField.label}}</mat-label>
          <input matInput [formControlName]="filterField.key">
          <button matSuffix type="button" (click)="resetFilter()" *ngIf="isSet" matTooltip="Wyczyść" color="primary" mat-icon-button aria-label="Clear">
            <mat-icon>clear</mat-icon>
          </button>
        </mat-form-field>
      } @case ('dropdown') {
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{filterField.label}}</mat-label>
          <mat-select [formControlName]="filterField.key">
            @for (opt of filterField.options; track opt.id) {
              <mat-option [value]="opt.id">{{ opt.name }}</mat-option>
            }
          </mat-select>
          <button matSuffix type="button" (click)="resetFilter()" *ngIf="isSet" matTooltip="Wyczyść" color="primary" mat-icon-button aria-label="Clear">
            <mat-icon>clear</mat-icon>
          </button>
        </mat-form-field>
      } @case ('datepicker') {
        <mat-form-field appearance="outline" class="w-full mb-2">
          <mat-label>{{filterField.label}}</mat-label>
          <input matInput [matDatepicker]="picker" [formControlName]="filterField.key">
          <mat-hint>DD.MM.RRRRR</mat-hint>
          <div class="flex" matSuffix>
            <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
            <button type="button" (click)="resetFilter()" *ngIf="isSet" matTooltip="Wyczyść" color="primary" mat-icon-button aria-label="Clear">
              <mat-icon>clear</mat-icon>
          </button>
          </div>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      }
    }
  </div>
  @if (!isValid) {<div class="errorMessage">{{ filterField.label }} is required</div>}
</div>
