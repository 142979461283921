import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FilterField } from '@common/models/lists/filters/filter-field';

@Component({
  selector: 'fa-filter-field',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatDatepickerModule
  ],
  templateUrl: './filter-field.component.html',
  styleUrl: './filter-field.component.scss'
})
export class FilterFieldComponent {
  @Input() filterField!: FilterField<string | number>;
  @Input() form!: FormGroup;

  @Output()
  resetField: EventEmitter<string | number |null> = new EventEmitter<string | number | null>();

  get isValid(): boolean {
    return this.form.controls[this.filterField.key]?.valid;
  }

  get isSet(): boolean {
    return this.form.controls[this.filterField.key]?.value ? true : false;
  }

  resetFilter() {
    const defaultValue: string | number | null = this.filterField.defaultValue || null;

    this.form.controls[this.filterField.key]?.setValue(defaultValue);
    this.resetField.emit(defaultValue);
  }
}
