import {Injectable} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { FilterField } from '@common/models/lists/filters/filter-field';

@Injectable({
  providedIn: 'root'
})
export class FiltersManagerService {
  toFormGroup(filterFields: FilterField<string>[]) {
    const filterGroup: any = {};

    filterFields.forEach((filterField) => {
      filterGroup[filterField.key] = new FormControl(filterField.value, {
        //updateOn: 'blur'
      });
    });
    return new FormGroup(filterGroup);
  }

  resetFormGroup(filterGroup: FormGroup, filterFields: FilterField<string | number>[] | null) {
    if(!filterFields) return;

    filterFields.forEach((filterField) => {
      filterGroup.controls[filterField.key]?.setValue(
        filterField.defaultValue || null
      )
    });
  }

  mapNullProperties(paramsWithNull: any) {
    const paramsWithoutNull = {...paramsWithNull};

    Object.keys(paramsWithoutNull).forEach(key => {
        if (paramsWithoutNull[key] === null || paramsWithoutNull[key] === -1 || paramsWithoutNull[key] === "-1") {
            paramsWithoutNull[key] = '';
        }
    });

    return paramsWithoutNull;
  }
}
