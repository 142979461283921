import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FilterFieldComponent } from '../filter-field/filter-field.component';
import { FilterField } from '@common/models/lists/filters/filter-field';
import { CommonModule } from '@angular/common';
import { debounceTime } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FilterState } from '@common/components/list-view.component';
import { FiltersManagerService } from '@common/services/lists/filters-manager.service';

@Component({
  selector: 'fa-filters',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FilterFieldComponent,
    MatButtonModule,
    MatIconModule,
    CommonModule
  ],
  templateUrl: './filters.component.html',
  styleUrl: './filters.component.scss'
})
export class FiltersComponent {
  @Output()
  filterStateChange = new EventEmitter<FilterState>();

  @Input()
  filterFields: FilterField<string | number>[] | null = [];

  filtersForm!: FormGroup;

  // @Input() questions: QuestionBase<string>[] | null = [];
  // form!: FormGroup;
  // payLoad = '';
  constructor(private filtersManagerService: FiltersManagerService) {}

  ngOnInit() {
    this.filtersForm = this.filtersManagerService.toFormGroup(this.filterFields?.sort(
      (a, b) => a.order - b.order
    ) as FilterField<string>[]);
  }

  onResetFilter() {
    this.filtersManagerService.resetFormGroup(this.filtersForm, this.filterFields);
    this.onSubmit();
  }

  onResetField() {
    this.onSubmit();
  }

  onSubmit() {
    const value = this.filtersForm.value;

    this.filterFields?.forEach(
      (item: any) => {
        if(item.controlType == "datepicker" && value[item.key]) {
          let date = new Date(value[item.key]);
          let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
          let month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
          let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);

          value[item.key] = `${year}-${month}-${day}`;
        }
      }
    )

    this.filterStateChange.emit(this.filtersForm.value);
  }

}
