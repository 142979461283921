import { Inject, Injectable } from "@angular/core";
import { APP_NAME } from "../tokens/app-name.token";
import { ENVIRONMENT_NAME } from "../tokens/environment-name.token";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageWithContextService {

  private appName!: string;
  private environmentName!: string;
  private prefix: string = "";

  private localStorage: any;

  private _userKey!: string;
  public set userKey(value: string) {
    this._userKey = value;
  }

  constructor(
    @Inject(APP_NAME) appName: string,
    @Inject(ENVIRONMENT_NAME) environmentName: string
  ) {
    if(!window.localStorage) throw new Error('Brak Obsługi `localStorage`');

    this.appName = appName;
    this.environmentName = environmentName;
    this.prefix = `${this.appName}_${this.environmentName}`;
    this.localStorage = window.localStorage;
  }

  public getPrefix(userContext: boolean = true): string {
    return userContext ? `${this.prefix}_${this._userKey}` : `${this.prefix}`;
  }

  public set(key:string, value:string, userContext: boolean = true):void {
    this.localStorage[`${this.getPrefix(userContext)}_${key}`] = value;
  }

  public get(key:string, userContext: boolean = true):string {
    return this.localStorage[`${this.getPrefix(userContext)}_${key}`] || false;
  }

  public setObject(key:string, value:any, userContext: boolean = true):void {
    this.localStorage[`${this.getPrefix(userContext)}_${key}`] = JSON.stringify(value);
  }

  public getObject(key:string, userContext: boolean = true):any {
    return JSON.parse(this.localStorage[`${this.getPrefix(userContext)}_${key}`] || null);
  }

  public remove(key:string, userContext: boolean = true):any {
    this.localStorage.removeItem(`${this.getPrefix(userContext)}_${key}`);
  }
}
