<div>
  <form (ngSubmit)="onSubmit()" [formGroup]="filtersForm">
    @for (filterField of filterFields; track filterField.key) {
      <div class="form-row">
        <fa-filter-field [filterField]="filterField" [form]="filtersForm" (resetField)="onResetField()"></fa-filter-field>
      </div>
    }
    <div class="flex justify-end">
      <button type="button" (click)="onResetFilter()" mat-icon-button matTooltip="Wyczyść filtr" color="primary" aria-label="Szukaj">
          <mat-icon>clear</mat-icon>
      </button>
      <button type="submit" mat-icon-button matTooltip="Szukaj" color="primary" aria-label="Szukaj">
          <mat-icon>search</mat-icon>
      </button>
  </div>
  </form>
</div>
