import { Inject, Injectable, WritableSignal, signal } from '@angular/core';
import { AuthConfig } from '../models/auth-config';
import { AUTH_CONFIG } from '../tokens/auth-config.token';
import { User } from '../models/user';
import { AuthCredentials } from '../models/auth-credentials';
import { LocalStorageWithContextService } from '../../common/services/local.storage.with.context.service';
import { Observable, map, of, tap } from 'rxjs';
import { AuthResponse } from '../models/auth-response';
import { RoleKey } from '../models/role-key';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ClientService } from './client.service';

/* TODO -> Przenieść do konfiguracji */
const DEFAULT_REDIRECT:string = "/";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  protected authApiURL!: string;
  protected authUserKey!: string;
  protected authTokenKey!: string;
  protected autoLogin!: boolean;

  public redirectUrl: string = DEFAULT_REDIRECT;
  public loginRedirectUrl: string = "/login";

  public user: WritableSignal<User | null> = signal(null);
  private _user: User | null = null;

  constructor(
    @Inject(AUTH_CONFIG) private config: AuthConfig,
    private localStorage: LocalStorageWithContextService,
    private router: Router,
    private http: HttpClient,
    private clientService: ClientService
  ) {
    this.authApiURL = config.authApiURL;
    this.redirectUrl = config.defaultRedirect || DEFAULT_REDIRECT;
    this.authTokenKey = config.authTokenKey;
    this.authUserKey = config.authUserKey;
    this.autoLogin = config.autoLogin;

    this.init();
  }

  protected init() {
    if(this.autoLogin) {
      const user = this.localStorage.getObject(this.authUserKey, false);
      const token = this.localStorage.get(this.authTokenKey, false);
      if(user && token) {
        this.afterLogin(user, token);
        // this.doRedirect();
      } else {
        // throw new Error("Próba automatycznego logowania nieudana - Brak zapamiętanych uwierzytelniających.");
      }
    }
  }

  private afterLogin(user: User, token: string) {
    this._user = user;

    this.localStorage.userKey = user.email;
    this.localStorage.set(this.authTokenKey, token, false);
    this.localStorage.setObject(this.authUserKey, this._user, false);

    this.user.set(user);
  }

  public loginX(credentials: AuthCredentials):  Observable<boolean> {
    const user = this._user = {
      id: 1,
      name: "Andrzej Frioartowski",
      firstname: "Andrzej",
      lastname: "Frioartowski",
      email: "andrzej.frioartowski@monitoring.frioarte.pl",
      user_status: {
        id: 1,
        name: "Aktywny",
        logic_name: "ACTIVE"
      },
      roles: [
        RoleKey.operator
      ],
      blocked: false,
      last_login_at: "2024-06-07 11:20:21"
    }

    const token: string = "878737847328174987918473892748";

    this.afterLogin(user, token);
    this.doRedirect();

    return of(true);
  }

  public login(credentials: AuthCredentials, passErrorsBack: boolean = false): Observable<boolean> {
    const credentialsWithClientId = Object.assign(
      {
        device_key: String(this.clientService.clientId)
      },
      credentials
    );

    const params: HttpParams =
      passErrorsBack
        ? new HttpParams().set('pass_errors', "true")
        : new HttpParams();

    return this.http.post<any>(`${this.authApiURL}/login`, credentialsWithClientId, { params: params } )
      .pipe(
        map(
          (authResesponse: AuthResponse) => {
            this.afterLogin(authResesponse.user, authResesponse.token);
            this.doRedirect();

            return true;
          }
        )
      );
  }

  public logout(fromAllClients: boolean = false): Observable<boolean> {
    this._user = null;
    this.user.set(this._user);

    this.router.navigate([this.loginRedirectUrl]);

    return this.http.post<any>(
      `${this.authApiURL}/logout`,
      {
        device_key: !fromAllClients ? "*" : String(this.clientService.clientId)
      }
      )
      .pipe(
        tap(
          () => {
            this.localStorage.userKey = "";
            this.localStorage.set(this.authTokenKey, "", false);
            this.localStorage.setObject(this.authUserKey, null, false);
          }
        ),
        map(
          () => {
            return true;
          }
        )
      );
  }

  public requestPasswordReset(email: string, passErrorsBack: boolean = false): Observable<boolean> {
    const params: HttpParams =
      passErrorsBack
        ? new HttpParams().set('pass_errors', "true")
        : new HttpParams();

    return this.http.post<boolean>(
        `${this.authApiURL}/forgotten_password`, { email: email },  { params: params }
    ).pipe(
      map(
        (response: any) => {
          return true;
        }
      )
    ) as Observable<boolean>;
  }

  public resetPassword(data: any, passErrorsBack: boolean = false): Observable<boolean> {
    const params: HttpParams =
      passErrorsBack
        ? new HttpParams().set('pass_errors', "true")
        : new HttpParams();

    return this.http.post<boolean>(
        `${this.authApiURL}/password_reset`, data, { params: params }
    ).pipe(
      map(
        (response: any) => {
          return true;
        }
      )
    ) as Observable<boolean>;
  }

  public loggedUserPerformsOneOfTheRoles(roles: RoleKey[]): boolean {
    if(this._user) {
      let userRoles: RoleKey[] = this._user.roles;

      return roles.length == 0 || roles.some(
        (roleKey) => {
          return userRoles.find((item: string) => item == roleKey);
        }
      )
    }

    return false;
  }

  public updatePassword(params: any): Observable<boolean> {
    return this.http.patch(
      `${this.authApiURL}/password_change`,
      params
    ).pipe(
      map(
        (response: any) => {
          return true;
        }
      )
    ) as Observable<boolean>;
  }

  private doRedirect() {
    // switch(user.roles[0]) {
    //     case RoleKey.operator:
    //         this.redirectUrl = OPERATOR_REDIRECT;
    //         break;
    //     case RoleKey.contractor_operator:
    //         this.redirectUrl = CONTRACTOR_OPERATOR_REDIRECT;
    //         break;
    //     case RoleKey.accounting:
    //         this.redirectUrl = ACCOUNTING_REDIRECT;
    //         break;
    //     case RoleKey.warehouse:
    //         this.redirectUrl = WAREHOUSE_REDIRECT;
    //         break;
    //     default:
    //         this.redirectUrl = DEFAULT_REDIRECT;
    // }

    // console.log(user, "dr", this.redirectUrl);


    this.router.navigate([this.redirectUrl]);
  }
}
